import React from "react";
import Header from "../components/header/header.js";
import WhatsappImg from "../assets/img/whatsapp.png";
import ChatGPT from "../assets/img/chatgpt.png";

import "../assets/styles/pages/_hospitality.scss";

const PageProps = {
    title: "Whatsapp e Intelligenza Artificiale",
    metaDescription: "",
};

const WhatsappAi = () => {
    return (
        <>
            <Header {...PageProps} />
            <div className="container hospitality">
                <div className="columns">
                    <div className="column">
                        <p>
              <span className="black bold">
               Su Eucleia puoi chattare direttamente con i tuoi clienti tramite WhatsApp dal pannello admin. Gestisci le conversazioni in maniera intelligente e senza limiti.

              </span>

                        </p>
                        <div className="section"/>
                        <p>
                            Immagina di poter gestire tutte le conversazioni con i tuoi clienti in un unico luogo, senza
                            dover passare da un'applicazione all'altra e senza limiti di operatore. Grazie alla potenza di WhatsApp, puoi offrire un
                            servizio di assistenza immediato, rispondere alle domande dei clienti e fornire informazioni
                            importanti, tutto senza lasciare il pannello admin di Eucleia.
                        </p><p>
                        Questa integrazione offre un'esperienza di supporto superiore, risparmiando tempo prezioso e
                        migliorando l'efficienza del tuo team. Con la chat di WhatsApp direttamente nell'ambiente
                        familiare di Eucleia, hai tutto ciò di cui hai bisogno per creare connessioni autentiche e
                        soddisfare al meglio le esigenze dei tuoi clienti.
                    </p>
                    </div>
                    <div className="column is-offset-1">
                        <img
                            src={WhatsappImg}
                            alt="Eucleia per Whatsapp"
                        />
                    </div>
                </div>
                <div className="section"/>
                <div className="columns">

                    <div className="column">
                        <img
                            src={ChatGPT}
                            alt="Eucleia per Whatsapp"
                        />
                    </div>
                    <div className="column">
                        <p>
              <span className="black bold">
                            Entra nel futuro con l'integrazione tra Whatsapp e l'Intelligenza Artificiale! Immagina un mondo in cui il customer support è più efficiente che mai.

              </span>

                        </p>
                        <div className="section"/>
                        <p>
                            La nostra soluzione combina la potenza di WhatsApp con l'AI di Chat GPT, Google Bard e molte altre, per offrire <stronng>un servizio clienti straordinario 24/7</stronng>.
                        </p><p>
                            Grazie a questa innovazione, puoi automatizzare risposte rapide alle domande più frequenti, gestire conversazioni multiple senza sforzo e fornire assistenza personalizzata in tempo reale. Che tu gestisca una piccola impresa o un'azienda di grandi dimensioni, questa integrazione è il tuo alleato per migliorare l'esperienza del cliente e aumentare la soddisfazione.
                        </p><p>
                            Immagina di risparmiare tempo prezioso, ridurre gli errori umani e soddisfare le aspettative dei tuoi clienti in modo impeccabile. È ora di abbracciare il futuro e trasformare il modo in cui gestisci i tuoi clienti. Scopri di più e sfrutta le infinite possibilità di questa integrazione rivoluzionaria!
                    </p>
                    </div>
                </div>
                <div className="section"/>
            </div>
        </>
    );
};

export default WhatsappAi;
